export default {
  title: 'dark',

  colors: {
    backgroundColor: '#272727',
    textColor: '#FFF',
    mainColor:
      'background: -webkit-linear-gradient(82deg, #00dcec 0%, #53f3ff 100%); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent;',
    iconBackgroundColor:
      'transparent linear-gradient(76deg, #00dcec 0%, #53f3ff 100%) 0% 0% no-repeat padding-box;',
    buttonColor:
      'background: transparent -webkit-linear-gradient(82deg, #00dcec 0%, #53f3ff 100%) 0% 0% no-repeat padding-box;',
    inputColor: '#41ebf8',
    iconColor: '#f4ede8',
    inputBackgroundColor: '#353535',
    inputTextColor: '#f4ede8',
    footerBackgroundColor: '#353535',
    cardsBackgroundColor: '#353535',
    rightNavBackgroundColor: '#484848',
    xIconRightNavColor: '#ccc',
    hamburguerIconRightNavColor: '#fff',
    h5TextColor: '#fff',
    inputPlaceholderColor: 'rgb(100,100,100)',
    buttonHover: '#68BBB1',
    iconHoverColor: '#41ebf8',
  },
};
